.message-container {
	text-align: center;
}

.message {
	display: inline-block;
	margin-bottom: 2rem;
	font-weight: 600;
	padding: 0.8em 1.5em;
	border-radius: 4px;
	opacity: 0;
	transition: opacity 0.4s;

	&--success {
		background-color: #35e077;
	}

	&--danger {
		background-color: #ff5f5f;
	}
}

.reveal {
	opacity: 1;
}
