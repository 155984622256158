.container {
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background-image: linear-gradient(to right bottom, $color-secondary-light, $color-secondary);
}

.game-intro {
	width: 85%;
	max-width: 60ch;
	box-shadow: 0 2rem 3rem rgba(0, 0, 0, 0.3);

	&__header {
		background-color: $color-primary;
		color: $color-secondary;
		text-align: center;
		border-radius: 4px 4px 0 0;
	}

	&__main {
		background-color: rgb(233, 233, 233);
		border-radius: 0 0 4px 4px;
	}

	&__header,
	&__main {
		padding: 2.5rem 3rem;
	}

	&__primary-heading {
		font-size: 4rem;
		margin-bottom: 0.7rem;
	}

	&__secondary-heading {
		font-weight: 500;
		font-size: 1.9rem;

		@include respond-min($bp-large) {
			font-size: 2.4rem;
		}
	}

	&__description {
		font-size: 1.7rem;
		margin-bottom: 2rem;
	}
}
