.question-container {
	padding: 7rem 0 3rem 0;
	display: flex;
	justify-content: center;
}

.question {
	text-align: center;
	background-color: $color-primary;
	color: $color-secondary;
	padding: 3rem;
	width: 85%;
	max-width: 65ch;
	border-radius: 4px;
	box-shadow: $shadow;

	&__title {
		font-size: 2.8rem;
		margin-bottom: 1.2rem;

		@include respond-min($bp-large) {
			font-size: 3.4rem;
		}
	}

	&__text {
		font-size: 1.7rem;

		@include respond-min($bp-large) {
			font-size: 2.3rem;
		}
	}
}
