/* VARIABLES */
$color-primary: #282b45;
$color-secondary: #e84949;
// $color-secondary: #d8a26f;
$color-secondary-light: #f35f5f;
$shadow: 0 1rem 3rem 1px rgba(0, 0, 0, 0.1);

/* MEDIA QUERIES */
$bp-large: 1800px;
$bp-medium: 900px;
$bp-small: 700px;
$bp-smaller: 550px;
$bp-smallest: 390px;

@mixin respond-max($breakpoint) {
	@media only screen and (max-width: $breakpoint / 16px * 1em) {
		@content;
	}
}

@mixin respond-min($breakpoint) {
	@media only screen and (min-width: $breakpoint / 16px * 1em) {
		@content;
	}
}

*,
*::before,
*::after {
	margin: 0;
	padding: 0;
	box-sizing: inherit;
}

html {
	font-size: 62.5%;
	box-sizing: border-box;

	@include respond-max($bp-medium) {
		font-size: 54%;
	}

	@include respond-max($bp-small) {
		font-size: 46%;
	}

	@include respond-max($bp-smallest) {
		font-size: 40%;
	}

	@include respond-min($bp-large) {
		font-size: 90%;
	}
}

body {
	font-family: 'Noto Sans', sans-serif;
	font-size: 1.6rem;
	background-color: #212337;
}

p {
	line-height: 1.6;
	font-weight: 500;
}

.link {
	text-decoration: none;
	color: $color-secondary;
}

.link:hover {
	border-bottom: 1px solid currentColor;
}

.btn-container {
	text-align: center;
}

.btn {
	display: inline-block;
	text-decoration: none;
	border: none;
	background-color: $color-primary;
	color: $color-secondary;
	font-size: 2.2rem;
	font-weight: 600;
	letter-spacing: 1px;
	padding: 0.7em 2em;
	border-radius: 5px;
	transition: opacity 0.4s;
	cursor: pointer;

	&:hover {
		opacity: 0.85;
	}
}

footer {
	background-color: $color-primary;
	padding: 2rem;
	text-align: center;
	color: $color-secondary;
}
