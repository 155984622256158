.overlay {
	position: fixed;
	top: 0;
	height: 100vh;
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 5;
	background-color: rgba(rgb(117, 117, 117), 0.75);
	backdrop-filter: blur(1rem);
}

.modal {
	background-color: #d5d5d5;
	color: $color-secondary;
	text-align: center;
	padding-bottom: 4rem;
	width: 80%;
	max-width: 50rem;
	border-radius: 4px;
	box-shadow: 0 1.5rem 3rem rgba(0, 0, 0, 0.5);
	overflow: hidden;

	&__header {
		padding: 2rem 0;
		background-color: $color-primary;
		color: $color-secondary;
		font-size: 2.6rem;
		margin-bottom: 3rem;
	}
}
