.info-bar {
	width: 100%;
	display: flex;
	justify-content: space-between;
	background-color: $color-primary;
	color: $color-secondary;
	font-size: 1.8rem;
	font-weight: 600;
	padding: 1em 2em;

	@include respond-max($bp-smallest) {
		font-size: 2rem;
		padding: 0.7em 1em;
	}
}
