.options-container {
	padding: 2rem 2rem 6rem;
}

.options {
	width: 100%;
	max-width: 160rem;
	margin: 0 auto;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(max-content, 25rem));
	gap: 3rem;
	justify-content: center;
}

.option {
	border: none;
	background-color: $color-primary;
	color: $color-secondary;
	text-align: center;
	border-radius: 4px;
	box-shadow: $shadow;
	cursor: pointer;
	transition: opacity 0.4s, transform 0.4s;
	overflow: hidden;

	&__details {
		padding: 1.6rem 0;
	}

	&__title {
		font-size: 2.5rem;
	}

	&__zone {
		font-size: 2rem;
	}

	&__image {
		display: block; // to get rid of the white space below the image
		width: 100%;
	}

	&:hover {
		opacity: 0.8;
		transform: translateY(-3px);
	}

	&--correct {
		border: 5px solid #35e077;
	}
}
